import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// Modules
import sidebar from "./modules/sidebar";
import auth from "./modules/auth";

export default new Vuex.Store({
  modules: {
    sidebar,
    auth
  }
})