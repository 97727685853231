import Vue from 'vue'
import Router from 'vue-router'
import { Auth } from "aws-amplify"

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Logs = () => import('@/views/Logs')
const Stats = () => import('@/views/Stats')
const Domains = () => import('@/views/Domains')
const Profile = () => import('@/views/Profile')
const Invoices = () => import('@/views/Invoices')
const Cancellation = () => import('@/views/Cancellation')
const Login = () => import('@/views/pages/Login')
const Page404 = () => import('@/views/pages/Page404')
const LoginInformation = () => import("@/views/LoginInformation");
const AccessControl = () => import("@/views/AccessControl");
const LogsExport = () => import("@/views/LogsExport");

// Admin pages
const Search = () => import('@/views/admin/Search')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {auth: true}
        },
        {
          path: 'logs',
          name: 'Logs',
          component: Logs,
          meta: {auth: false}
        },
        {
          path: 'stats',
          name: 'Statistic',
          component: Stats,
          meta: {auth: true}
        },
        {
          path: 'domain',
          name: 'Domains',
          component: Domains,
          meta: {auth: true}
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile,
          meta: {auth: true}
        },
        {
          path: 'invoices',
          name: 'Invoices',
          component: Invoices,
          meta: {auth: true}
        },
        {
          path: 'cancel',
          name: 'Cancel Subscription',
          component: Cancellation,
          meta: {auth: true}
        },
        {
          path: 'acl',
          name: 'Access Control',
          component: AccessControl,
          meta: {auth: true}
        },
        {
          path: 'log-export',
          name: 'Exported Logs',
          component: LogsExport,
          meta: {auth: true}
        },
      ]
    },
    {
      path: '/admin',
      redirect: '/admin/search',
      name: 'Admin',
      component: TheContainer,
      children: [
        {
          path: 'search',
          name: 'Search',
          component: Search,
          meta: {auth: true, admin: true}
        },
      ]
    },
    {
      path: '/user',
      redirect: '/pages/404',
      name: 'User',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    },
    {
      path: '/credentials',
      name: 'Login Information',
      component: LoginInformation
    },
    {
      path: '/error',
      name: 'Error',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
      ]
    },
    {
      path: '*',
      name: 'NotFound',
      redirect: '/error/404'
    },
  ]
}

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.auth);
  const requiresAdminAccess = to.matched.some(record => record.meta.admin);
  let currentUser = null;

  try {
    currentUser = await Auth.currentAuthenticatedUser();

  } catch (err) {
    console.log('Err', err);
  }

  if(to.name === 'Login' && currentUser) {
    next('/');
  } else {
    if(requiresAuth) {
      if(currentUser) {
        if(requiresAdminAccess
            &&
            (
              !currentUser.signInUserSession.idToken.payload.hasOwnProperty('cognito:groups')
                ||
              !currentUser.signInUserSession.idToken.payload['cognito:groups'].includes('admin')
            )) {

          next('/');
        } else {
          next();
        }
      } else {
        next('/user/login');
      }
    } else {
      next();
    }
  }
})

export default router
