<template>
  <router-view></router-view>
</template>

<script>
import {AuthState, onAuthUIStateChange} from "@aws-amplify/ui-components";
export default {
  name: 'App',
  data() {
    return {
      unsubscribeAuth: null
    }
  },
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((newAuthState, authData) => {
      if(newAuthState === AuthState.SignedIn && authData) {
        const userGroups = authData.signInUserSession.idToken.payload['cognito:groups'] || [];

        if(userGroups.includes('admin')) {
          window.location = '/admin/search';
        } else {
          window.location = '/domain';
        }

      } else if(newAuthState === AuthState.SignedOut) {
        this.$router.push('/user/login');
      }
    });
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
