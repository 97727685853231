export default {
    state: {
        signedIn: false,
        user: null
    },
    mutations: {
        signInUser(state, userData) {
            state.user = userData;
            state.signedIn = true;
        },
        signOutUser(state) {
            state.user = null;
            state.signedIn = false;
        }
    }
}